import React from "react";
import styles from "../../css/AboutCss/AboutCertified.module.scss";

const certifications = [
  { imgSrc: "/images/Certified/riyada.webp", width: "55%" },
  { imgSrc: "/images/Certified/wca.webp", width: "101%" },
  { imgSrc: "/images/Certified/fiata.webp", width: "69%" },
  { imgSrc: "/images/Certified/ISOLogo.webp", width: "36%" },
  { imgSrc: "/images/Certified/x.webp", width: "80%" },
];

const AboutCertified = () => {
  return (
    <div className="container container-sm container-md container-lg container-xl container-xxl">
      <div className="row">
        <div className="col-lg-12">
          <h1 className={styles.AboutCertifiedHOne}>
            Memberships And Certifications
          </h1>
        </div>
      </div>

      <div className="row certified-about-top">
        {certifications.map((certification, index) => (
          <div className="col-lg" key={index}>
            <div className={`${styles.certifiedBox}`}>
              <div className={` ${styles.cerifiedImgRow}`}>
                <img
                  src={certification.imgSrc}
                  style={{ width: certification.width }}
                  alt={`Certification ${index + 1}`}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutCertified;
